.title__text{
    color: #fff!important;
    text-align: center!important;
}
.text__sub{
    font-size: 1rem;
}
.uk-switch {
    position: relative;
    display: inline-block;
    height: 34px;
    width: 60px;
  }
/* Slider */
.uk-switch-slider {
    background-color: rgba(0,0,0,0.22);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    border-radius: 500px;
    bottom: 0;
    cursor: pointer;
    transition-property: background-color;
      transition-duration: .2s;
    box-shadow: inset 0 0 2px rgba(0,0,0,0.07);
  }
  /* Switch pointer */
  .uk-switch-slider:before {
    content: '';
    background-color: #fff;
    position: absolute;
    width: 30px;
    height: 30px;
    left: 2px;
    bottom: 2px;
    border-radius: 50%;
    transition-property: transform, box-shadow;
      transition-duration: .2s;
  }
  /* Slider active color */
  input:checked + .uk-switch-slider {
    background-color: #39f !important;
  }
  /* Pointer active animation */
  input:checked + .uk-switch-slider:before {
    transform: translateX(26px);
  }
  