
.container_scroll{
    width: auto;
    overflow-y: hidden;
    overflow: auto;
    background-color: #fff;
    box-shadow: 0 5px 15px rgba(0,0,0,.15);
    padding: 10px!important;
    box-sizing: border-box;
 
}
.container__flex{
    flex: 1;
    display: flex;
    justify-content: center;
    flex-grow: 1;
    margin: auto;
    width: min-content;
}
.container__column{
    border: 2px solid rgba(0,0,0,.15);
    margin-left: 3px;
    width: 250px;
    border-radius: 5px;
    box-sizing: border-box;
    height: 710px;
    overflow-x: hidden;
    overflow-y: auto;

}
.container__column p{
    margin-left: 5px;
    margin-bottom: 0;
}
.container__column h4{
    font-size: 16px;
    font-weight: bold;
    text-align: left;
    margin: 0;
    margin-left: 5px;
}
.container__column .title{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    height: 75px;
    flex-grow: 0;
    
}
.column__body{
    flex: content;
    border-top: 10px solid rgba(0,0,0,.15);
    border-radius: 5px;
    box-sizing: border-box;
    min-height: 600px;


}

.container__item{
    margin: 5px;
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 5px 15px;
    background-color: #4871a9!important;
    min-height: 80px;

}
.container__item h3{
    color: white!important;
    padding-left: 10px;
    font-size: 15px;
    font-weight: bold;
    margin: 0;

}
.container__item p{
    color: white!important;
    padding-left: 10px;
    font-size: 14px;
    margin: 0;

}
.container__item a{
    margin-bottom: 10px;

}

/******
Contenedor de botones
******/
.container_auto{
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    min-width: 1px;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;

}

@media(max-width:600px){
    .container__flex{
        display: flex;
        width: min-content;
        overflow: auto;

    }
    .container__column{
        width: 225px;
        max-height: 450px;
    
    }
    .container__column h4{
        font-size: 14px;
    }
    .column__body{
        min-height:350px;
    }
    .item{
        font-size: 12px;

    }
    .container_auto{
        flex-direction: column;
    }

}


@media(max-width:375px){
    .container__column{
        width: 225px;
        max-height: 325px;
    }
    
}



progress {
	display:block;
    -webkit-appearance: none;
    width: 99%;
    margin: 2px;
}

progress::-webkit-progress-bar {
	background: white;
	border-radius: 2.5px;
	padding: 2px;
}
progress::-moz-progress-bar {  
	background:white;
	border-radius: 2.5px;
	padding: 2px;
} 

progress::-webkit-progress-value {
	border-radius: 2.5px;
	background:rgb(196, 24, 60);
}