/* Modal */
/* .ReactModalPortal > div{
    opacity: 0;
}

.ReactModalPortal .ReactModal__Overlay {
    align-items: center;
    display: flex;
    justify-content: center;
    transition: opacity .2s ease-in-out;
    z-index: 999;
}

.modal-fondo {
    background-color: rgba(0, 0, 0, 0.3);
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    position: fixed;
}

.ReactModalPortal .ReactModal__Overlay--after-open {
    opacity: 1;
}

.ReactModalPortal .ReactModal__Overlay--before-close {
    opacity: 0;
} */

/* .modal-fondo {
    background-color: rgba(0, 0, 0, 0.3);
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    position: fixed;
} */

/* .Modal {
    /* position: absolute;
    top: 40px;
    left: 40px;
    right: 40px;
    bottom: 40px;
    background-color: papayawhip; 

    content {

        top: 50%;
        left: 50%;
        right: auto;
        bottom: auto;
        margin-right: -50%;
        transform: translate(-50%, -50%);
        max-width: 400px;
        width: auto;
        padding: 20px;
    }
  } */

.Overlay {
    background-color: rgba(0, 0, 0, 0.3);
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    position: fixed;
}

/* .modal1 {
    border: solid 2px  blue;
    /* background-color: blue; */
    /* background: white;
    border-radius: 5px;
    color: rgb(51, 51, 51);
    display: inline; */
    /* max-height: 620px;
    max-width: 500px;
    outline: none;
    padding: 10px; 
} */

/* .close{
    
    

} */

.close:hover{
    color: rgb(44, 42, 42);
}