.login-overlay .panel-body{
    min-height: 244px;
}
.has-feedback{
    position: relative;
}
.form-control-feedback{
    right: 10px;
    left: auto;
    top: 10px;
    position: absolute;
}
.login-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.card-login {
    border: 0;
    border-radius: 1rem;
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
    opacity: 0.9;
    padding: 2rem;

}
.bienvenida{
    color:white;
    /* font-weight: bold; */
}
.buttons-box{
    display: flex;
    align-items: center;
    justify-content: center;
}
.blue-gradient-bg{
    height: 100vh;
    background: #007bff;
    background: linear-gradient(to right, #0062E6, #3ac1ff);
}

.image-background{
    height: 100vh;
    background-image: url("../../../../../../src/assets/img/fondo.jpg");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
}

.link-hover{
    color: white;
}

.link-hover:hover{
    color: white;
}
